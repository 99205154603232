[data-component="newsletter"] {
  display: flex;
  align-items: center;
  justify-content: center; }
  [data-component="newsletter"] .newsletter {
    min-height: 200px;
    width: 800px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    background: white;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-top: 5px solid #f3b520;
    transition: .3s;
    display: none; }
    @media (max-width: 575px) {
      [data-component="newsletter"] .newsletter {
        width: 100%;
        padding: 20px; } }
    [data-component="newsletter"] .newsletter[data-visible=true] {
      opacity: 1;
      display: flex; }
    [data-component="newsletter"] .newsletter .newsletter-title {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.2;
      font-family: 'Hind Vadodara', sans-serif;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: normal;
      text-align: center;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }
    [data-component="newsletter"] .newsletter .newsletter-text {
      font-weight: 300;
      font-size: 16px;
      text-align: center;
      margin-top: 20px; }
    [data-component="newsletter"] .newsletter .newsletter-input {
      font-weight: 300;
      font-size: 16px;
      text-align: left;
      margin: 0 auto;
      margin-top: 20px;
      height: 50px;
      background: white;
      background: #f9f9f9;
      border: none;
      width: 400px;
      border: 1px solid #ddd;
      padding: 0 10px;
      outline: none;
      text-align: center; }
      @media (max-width: 575px) {
        [data-component="newsletter"] .newsletter .newsletter-input {
          width: 100%; } }
    [data-component="newsletter"] .newsletter .newsletter-button {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.2;
      font-family: 'Hind Vadodara', sans-serif;
      height: 40px;
      background: #f2ae0d;
      color: white;
      padding: 0 20px;
      margin: 0 auto;
      margin-top: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .15s;
      user-select: none; }
      [data-component="newsletter"] .newsletter .newsletter-button:hover {
        transform: scale(1.05); }
      [data-component="newsletter"] .newsletter .newsletter-button:active {
        transform: scale(0.95); }
