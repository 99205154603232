[data-component="image"] .image-box {
  width: calc(100% + 160px);
  margin-left: -80px;
  margin-bottom: 40px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  @media (max-width: 575px) {
    [data-component="image"] .image-box {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-bottom: 20px; } }

[data-component="image"] .normal-image-box {
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }

[data-component="image"] .image {
  position: relative;
  border: 1px solid #eee; }
  [data-component="image"] .image .image-caption {
    position: absolute;
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 10px 40px;
    left: -20px;
    bottom: 20px;
    z-index: 5;
    max-width: calc(50%);
    background: #333;
    background: #f2ae0d;
    color: white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif; }
    @media (max-width: 575px) {
      [data-component="image"] .image .image-caption {
        padding: 10px 10px;
        left: -10px;
        bottom: 10px;
        max-width: calc(80%); } }
  [data-component="image"] .image img {
    width: 100%; }
  [data-component="image"] .image .image-element {
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 575px) {
      [data-component="image"] .image .image-element {
        height: 250px; } }
