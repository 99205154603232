[data-screen="category"] .category .category-hero {
  position: relative;
  height: 600px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 0px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start; }
  [data-screen="category"] .category .category-hero .hero-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f9f9f9;
    background-size: cover;
    background-position: center;
    z-index: 3; }
  [data-screen="category"] .category .category-hero .hero-overlay {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 20px 40px;
    margin-left: -20px;
    margin-bottom: 20px;
    z-index: 5;
    max-width: calc(100%); }
  [data-screen="category"] .category .category-hero .hero-author {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    margin-bottom: 10px;
    z-index: 5;
    max-width: calc(100%);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 14px;
    font-weight: 500; }
  [data-screen="category"] .category .category-hero .hero-title {
    font-size: 32px;
    font-weight: 100;
    line-height: 1.3; }
    @media (max-width: 575px) {
      [data-screen="category"] .category .category-hero .hero-title {
        font-size: 20px; } }

[data-screen="category"] .category .category-tags {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  margin-top: 30px; }
  [data-screen="category"] .category .category-tags .category-tag {
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
    [data-screen="category"] .category .category-tags .category-tag:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    [data-screen="category"] .category .category-tags .category-tag:hover {
      background: #f2ae0d;
      color: white; }

[data-screen="category"] .category .hero-summary {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  background: white;
  padding: 20px 60px;
  z-index: 5;
  background: #f2ae0d;
  color: white;
  font-size: 18px;
  line-height: 1.6; }
  [data-screen="category"] .category .hero-summary a {
    color: inherit; }
  [data-screen="category"] .category .hero-summary p {
    font-size: inherit;
    font-weight: inherit; }
    [data-screen="category"] .category .hero-summary p:first-child {
      margin-top: 0;
      padding-top: 0; }

[data-screen="category"] .category .category-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%; }

[data-screen="category"] .category .category-share {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-left: -50px;
  margin-top: -90px;
  margin-bottom: 40px;
  flex-direction: column;
  margin-left: -90px;
  margin-top: -20px; }
  [data-screen="category"] .category .category-share .share-item {
    height: 50px;
    width: 50px;
    background: #f2ae0d;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-left: 10px;
    margin-top: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: .15s;
    user-select: none; }
    [data-screen="category"] .category .category-share .share-item:hover {
      transform: scale(1.05); }
    [data-screen="category"] .category .category-share .share-item:active {
      transform: scale(0.95); }

[data-screen="category"] .category .category-content {
  font-size: 18px;
  line-height: 2;
  font-weight: 300;
  margin-top: 40px;
  position: relative;
  padding: 60px;
  background: white; }
  [data-screen="category"] .category .category-content p {
    margin-bottom: 40px; }
  [data-screen="category"] .category .category-content a {
    color: #f3b520;
    font-weight: 500; }
