[data-component="text"] .text-paper {
  font-size: 18px;
  line-height: 2;
  font-weight: 300;
  position: relative;
  padding: 60px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  background: white; }
  @media (max-width: 575px) {
    [data-component="text"] .text-paper {
      padding: 20px;
      font-size: 14px; } }
