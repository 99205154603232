[data-component="hero"] {
  min-height: 550px; }
  [data-component="hero"] .hero {
    min-height: 550px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border: 5px solid white;
    border-left: none;
    border-bottom: none; }
    [data-component="hero"] .hero .overlay-stats {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: uppercase;
      line-height: 1.2;
      font-family: 'Hind Vadodara', sans-serif;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      [data-component="hero"] .hero .overlay-stats .overlay-stat {
        padding: 0 15px;
        border-left: 1px solid white;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        align-items: center; }
        [data-component="hero"] .hero .overlay-stats .overlay-stat i {
          font-size: 16px;
          margin-right: 10px; }
        [data-component="hero"] .hero .overlay-stats .overlay-stat:first-child {
          padding-left: 0;
          border-left: 0; }
    [data-component="hero"] .hero .hero-main {
      position: relative;
      flex: 1 1 600px;
      overflow: hidden;
      min-height: 149px;
      height: 545px;
      border-left: 5px solid white;
      border-bottom: 5px solid white; }
      @media (max-width: 575px) {
        [data-component="hero"] .hero .hero-main {
          height: 350px; } }
      [data-component="hero"] .hero .hero-main:hover .main-image {
        transform: scale(1.05);
        filter: brightness(150%); }
      [data-component="hero"] .hero .hero-main .main-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: 1s;
        will-change: transform; }
      [data-component="hero"] .hero .hero-main .main-overlay, [data-component="hero"] .hero .hero-main .hero-items .hero-item .item-overlay, [data-component="hero"] .hero .hero-items .hero-item .hero-main .item-overlay {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 40px;
        background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.9));
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%);
        color: white; }
        [data-component="hero"] .hero .hero-main .main-overlay .overlay-title, [data-component="hero"] .hero .hero-main .hero-items .hero-item .item-overlay .overlay-title, [data-component="hero"] .hero .hero-items .hero-item .hero-main .item-overlay .overlay-title {
          font-size: 32px;
          font-weight: 100;
          line-height: 1.3;
          margin-top: 10px;
          margin-right: 40px; }
          @media (max-width: 575px) {
            [data-component="hero"] .hero .hero-main .main-overlay .overlay-title, [data-component="hero"] .hero .hero-main .hero-items .hero-item .item-overlay .overlay-title, [data-component="hero"] .hero .hero-items .hero-item .hero-main .item-overlay .overlay-title {
              font-size: 20px; } }
        [data-component="hero"] .hero .hero-main .main-overlay .overlay-author, [data-component="hero"] .hero .hero-main .hero-items .hero-item .item-overlay .overlay-author, [data-component="hero"] .hero .hero-items .hero-item .hero-main .item-overlay .overlay-author {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          text-transform: uppercase;
          line-height: 1.2;
          font-family: 'Hind Vadodara', sans-serif; }
    [data-component="hero"] .hero .hero-items {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      margin-left: 5px;
      min-width: 200px; }
      [data-component="hero"] .hero .hero-items .hero-item {
        flex: 1 1 auto;
        height: 149px;
        position: relative;
        border-bottom: 5px solid white;
        overflow: hidden; }
        [data-component="hero"] .hero .hero-items .hero-item:hover .item-image {
          transform: scale(1.05);
          filter: brightness(150%); }
        [data-component="hero"] .hero .hero-items .hero-item:first-child {
          margin-top: 0px;
          height: 150px; }
        [data-component="hero"] .hero .hero-items .hero-item .item-image {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: 1s;
          will-change: transform;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat; }
        [data-component="hero"] .hero .hero-items .hero-item .item-overlay {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          padding: 20px;
          background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0.9));
          padding-top: 40px;
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.5) 100%);
          color: white; }
          [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.2;
            margin-top: 10px; }
          [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-author {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0px;
            text-transform: uppercase;
            line-height: 1.2;
            font-family: 'Hind Vadodara', sans-serif; }
          [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-stats {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0px;
            text-transform: uppercase;
            line-height: 1.2;
            font-family: 'Hind Vadodara', sans-serif;
            margin-top: 10px;
            display: none; }
            [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-stats .overlay-stat {
              padding: 0 10px; }
              [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-stats .overlay-stat i {
                font-size: 10px;
                margin-right: 5px;
                position: relative;
                top: 1px; }
              [data-component="hero"] .hero .hero-items .hero-item .item-overlay .overlay-stats .overlay-stat:first-child {
                padding-left: 0; }
