[data-component="randoms"] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -20px; }
  [data-component="randoms"] .random {
    display: flex;
    flex-direction: column;
    min-height: 350px;
    flex: 1 1 250px;
    position: relative;
    background: white;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 0px;
    overflow: hidden;
    max-width: 660px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none; }
    [data-component="randoms"] .random:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    @media (max-width: 575px) {
      [data-component="randoms"] .random {
        min-height: 250px; } }
    [data-component="randoms"] .random:hover .item-image {
      transform: scale(1.05);
      filter: brightness(150%); }
    [data-component="randoms"] .random:nth-child(4n) {
      flex: 1 1 450px;
      max-width: 660px; }
    [data-component="randoms"] .random:nth-child(3n - 1) {
      flex: 1 1 300px; }
    [data-component="randoms"] .random .item-image {
      height: 200px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: 1s;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: cover;
      object-position: center;
      width: 100%; }
      @media (max-width: 575px) {
        [data-component="randoms"] .random .item-image {
          height: 150px; } }
    [data-component="randoms"] .random .item-overlay {
      position: relative;
      z-index: 2;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 20px 20px 30px;
      background: white;
      color: black; }
      [data-component="randoms"] .random .item-overlay .overlay-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        margin-top: 10px; }
        @media (max-width: 575px) {
          [data-component="randoms"] .random .item-overlay .overlay-title {
            font-size: 14px;
            font-weight: bold; } }
      [data-component="randoms"] .random .item-overlay .overlay-author {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        color: #f3b520; }
