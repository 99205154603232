[data-component="video"] {
  margin: 0 auto; }
  [data-component="video"] .video-order {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-top: -40px; }
  [data-component="video"] .video-box {
    margin-top: 40px;
    margin-left: 40px;
    position: relative;
    width: 100%;
    padding-top: 40%;
    /* 1:1 Aspect Ratio */
    max-width: 900px;
    flex: 1 1 700px; }
    [data-component="video"] .video-box .video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      [data-component="video"] .video-box .video .yt-video-embed {
        width: 100%;
        height: 100%; }
  [data-component="video"] .video-caption {
    margin-top: 40px;
    margin-left: 40px;
    flex: 1 1 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    [data-component="video"] .video-caption .caption-title {
      font-size: 32px;
      font-weight: 100;
      line-height: 1.3; }
      @media (max-width: 575px) {
        [data-component="video"] .video-caption .caption-title {
          font-size: 20px; } }
    [data-component="video"] .video-caption .caption-description {
      margin-top: 20px;
      font-weight: 300;
      font-size: 16px;
      font-size: 18px;
      line-height: 2;
      font-weight: 300; }
