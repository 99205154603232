[data-component="header"] {
  height: 110px; }
  @media (max-width: 575px) {
    [data-component="header"] {
      height: 50px; } }
  [data-component="header"][data-active="false"] .header {
    transition: .4s; }
    [data-component="header"][data-active="false"] .header .header-right .social-links i, [data-component="header"][data-active="false"] .header .header-left .social-links i {
      background: none;
      box-shadow: none; }
    [data-component="header"][data-active="false"] .header .header-left .menu-button {
      background: none;
      box-shadow: none; }
  [data-component="header"][data-active="false"] .header-menu {
    top: -10px;
    transition: .4s;
    opacity: 0; }
  [data-component="header"] .header {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f3b520;
    color: #1b1b1b;
    user-select: none;
    font-family: 'Hind Vadodara', sans-serif;
    height: 70px;
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    transition: .4s; }
    [data-component="header"] .header .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
    @media (max-width: 575px) {
      [data-component="header"] .header {
        height: 50px; } }
    [data-component="header"] .header .header-title {
      font-size: 28px;
      font-weight: 100;
      line-height: 1;
      text-transform: uppercase;
      cursor: pointer;
      transition: .15s;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      padding: 0 20px;
      transition: .4s;
      width: 220px;
      position: absolute;
      left: 50%;
      margin-left: -110px; }
      [data-component="header"] .header .header-title:hover {
        transform: scale(1.05); }
      [data-component="header"] .header .header-title:active {
        transform: scale(0.95); }
      [data-component="header"] .header .header-title svg {
        fill: #1b1b1b;
        transition: .4s;
        height: 65px;
        width: auto; }
        @media (max-width: 575px) {
          [data-component="header"] .header .header-title svg {
            height: 35px; } }
    [data-component="header"] .header .header-categories {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: inherit;
      z-index: 900;
      font-family: 'Hind Vadodara', sans-serif;
      will-change: top, opacity, background;
      margin-left: auto; }
      @media (max-width: 575px) {
        [data-component="header"] .header .header-categories {
          display: none; } }
      @media (max-width: 991px) {
        [data-component="header"] .header .header-categories {
          display: none; } }
      @media (max-width: 1180px) {
        [data-component="header"] .header .header-categories {
          display: none; } }
      [data-component="header"] .header .header-categories .menu-item {
        padding: 0 10px;
        line-height: 40px;
        cursor: pointer;
        transition: .15s;
        user-select: none;
        text-decoration: none;
        color: #1b1b1b;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        text-transform: none;
        transition: all 0s, transform .1s;
        letter-spacing: 0px;
        font-size: 12px;
        font-weight: 500;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px; }
        [data-component="header"] .header .header-categories .menu-item:hover {
          transform: scale(1.05); }
        [data-component="header"] .header .header-categories .menu-item:active {
          transform: scale(0.95); }
    [data-component="header"] .header .header-right, [data-component="header"] .header .header-left {
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      @media (max-width: 575px) {
        [data-component="header"] .header .header-right, [data-component="header"] .header .header-left {
          display: none;
          height: 50px; } }
      @media (max-width: 991px) {
        [data-component="header"] .header .header-right .social-gap, [data-component="header"] .header .header-left .social-gap {
          display: none; } }
      [data-component="header"] .header .header-right .social-links, [data-component="header"] .header .header-left .social-links {
        display: flex;
        flex-direction: row;
        font-size: 23px;
        padding: 0 10px;
        font-weight: 300; }
        @media (max-width: 991px) {
          [data-component="header"] .header .header-right .social-links:first-child, [data-component="header"] .header .header-left .social-links:first-child {
            display: none; } }
        [data-component="header"] .header .header-right .social-links .fab, [data-component="header"] .header .header-left .social-links .fab, [data-component="header"] .header .header-right .social-links .fa-search, [data-component="header"] .header .header-left .social-links .fa-search {
          text-decoration: none;
          font-size: 18px;
          margin: 0 5px;
          transform: translateY(0px);
          cursor: pointer;
          height: 40px;
          width: 40px;
          line-height: 40px;
          background: #fff;
          color: #1b1b1b;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
          transition: .4s;
          cursor: pointer;
          transition: .15s;
          user-select: none; }
          [data-component="header"] .header .header-right .social-links .fab:hover, [data-component="header"] .header .header-left .social-links .fab:hover, [data-component="header"] .header .header-right .social-links .fa-search:hover, [data-component="header"] .header .header-left .social-links .fa-search:hover {
            transform: scale(1.05); }
          [data-component="header"] .header .header-right .social-links .fab:active, [data-component="header"] .header .header-left .social-links .fab:active, [data-component="header"] .header .header-right .social-links .fa-search:active, [data-component="header"] .header .header-left .social-links .fa-search:active {
            transform: scale(0.95); }
        [data-component="header"] .header .header-right .social-links .fa-search, [data-component="header"] .header .header-left .social-links .fa-search {
          background: black;
          color: white; }
    [data-component="header"] .header .header-left {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0; }
      @media (max-width: 575px) {
        [data-component="header"] .header .header-left {
          display: block; } }
      [data-component="header"] .header .header-left .menu-button {
        height: 40px;
        width: 40px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        transform: translateY(0px);
        cursor: pointer;
        transition: .4s;
        color: #1b1b1b;
        margin: 15px;
        margin-right: 0;
        cursor: pointer;
        transition: .15s;
        user-select: none; }
        @media (max-width: 575px) {
          [data-component="header"] .header .header-left .menu-button {
            height: 50px;
            width: 50px;
            margin: 0; } }
        [data-component="header"] .header .header-left .menu-button:hover {
          transform: scale(1.05); }
        [data-component="header"] .header .header-left .menu-button:active {
          transform: scale(0.95); }
      [data-component="header"] .header .header-left .menu-text {
        cursor: pointer;
        line-height: 60px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif; }
        @media (max-width: 575px) {
          [data-component="header"] .header .header-left .menu-text {
            display: none; } }
  [data-component="header"] .header-menu {
    display: flex;
    flex: 0 0 auto;
    height: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #edab0d;
    position: fixed;
    left: 0;
    right: 0;
    top: 30px;
    z-index: 900;
    font-family: 'Hind Vadodara', sans-serif;
    transform: translateY(40px);
    transition: .4s;
    will-change: top, opacity, background; }
    @media (max-width: 575px) {
      [data-component="header"] .header-menu {
        display: none; } }
    [data-component="header"] .header-menu .menu-item {
      padding: 0 20px;
      line-height: 40px;
      cursor: pointer;
      transition: .15s;
      user-select: none;
      text-decoration: none;
      color: inherit;
      color: #1b1b1b;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.2;
      font-family: 'Hind Vadodara', sans-serif;
      font-size: 13px;
      font-weight: 500; }
      [data-component="header"] .header-menu .menu-item:hover {
        transform: scale(1.05); }
      [data-component="header"] .header-menu .menu-item:active {
        transform: scale(0.95); }
