[data-component="hashes"] {
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  margin-bottom: -20px; }
  @media (max-width: 991px) {
    [data-component="hashes"] {
      flex-direction: column; } }
  [data-component="hashes"] .hash-stories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 100%; }
  [data-component="hashes"] .hash-tag {
    background: #f3b520;
    color: white;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 30px;
    margin-left: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    letter-spacing: normal;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    flex: 1 0 auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-direction: row; }
    [data-component="hashes"] .hash-tag i {
      font-size: 30px;
      margin-right: 5px; }
  [data-component="hashes"] .hash {
    flex: 1 1 200px;
    position: relative;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none; }
    [data-component="hashes"] .hash:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    [data-component="hashes"] .hash:hover .item-image {
      filter: brightness(150%); }
    [data-component="hashes"] .hash .item-image {
      height: 200px;
      height: 270px;
      height: 180px;
      margin-right: 0px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      transition: 1s; }
      @media (max-width: 575px) {
        [data-component="hashes"] .hash .item-image {
          height: 150px; } }
    [data-component="hashes"] .hash .item-overlay {
      margin-top: -10px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 20px;
      position: relative;
      z-index: 2;
      border: 1px solid #eee;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      background: white;
      color: black; }
      [data-component="hashes"] .hash .item-overlay .overlay-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 10px; }
        @media (max-width: 575px) {
          [data-component="hashes"] .hash .item-overlay .overlay-title {
            font-size: 14px; } }
      [data-component="hashes"] .hash .item-overlay .overlay-author {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        color: #f3b520; }
