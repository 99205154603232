[data-component="footer"] {
  background: #f2ae0d;
  background: #000;
  color: white;
  justify-self: flex-end;
  margin-top: auto; }
  [data-component="footer"] .footer {
    min-height: 300px;
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    flex: 1 0 auto;
    position: relative;
    flex-wrap: wrap; }
    [data-component="footer"] .footer .footer-logo {
      flex: 0 1 300px;
      min-width: 200px;
      margin-bottom: 20px; }
      [data-component="footer"] .footer .footer-logo img {
        width: 200px; }
    [data-component="footer"] .footer .footer-copyright {
      flex: 0 0 100%;
      font-size: 12px; }
      [data-component="footer"] .footer .footer-copyright a {
        color: inherit; }
    [data-component="footer"] .footer .footer-title {
      flex: 2 1 300px;
      font-size: 33px;
      font-weight: 100;
      line-height: 1;
      text-transform: uppercase;
      align-self: flex-end; }
    [data-component="footer"] .footer .footer-links {
      flex: 1 1 250px;
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px; }
      [data-component="footer"] .footer .footer-links .footer-link {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        font-size: 14px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        transition: .15s;
        flex: 1 1 150px; }
        [data-component="footer"] .footer .footer-links .footer-link:hover {
          color: white; }
