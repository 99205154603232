[data-component='profile'] .profile-box {
  background: white;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  position: relative;
  min-height: 250px;
  position: relative;
  border: 1px solid #eee; }
  [data-component='profile'] .profile-box .profile-twitter {
    color: #f2ae0d;
    font-weight: bold; }
  [data-component='profile'] .profile-box h1 {
    margin-bottom: 0px;
    margin-right: 10px; }
  [data-component='profile'] .profile-box p {
    margin-top: 10px; }
  [data-component='profile'] .profile-box .box-top {
    height: 100px;
    background: #f3b520;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  [data-component='profile'] .profile-box .box-profile {
    flex: 0 0 140px;
    height: 140px;
    width: 140px;
    border-radius: 300px;
    background: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid white;
    margin: auto 15px 15px;
    margin-top: -85px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden; }
    [data-component='profile'] .profile-box .box-profile img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    [data-component='profile'] .profile-box .box-profile[data-default='true'] {
      background-size: cover;
      background-color: #000;
      padding: 20px; }
      [data-component='profile'] .profile-box .box-profile[data-default='true'] img {
        object-fit: contain;
        margin: 10px; }
  [data-component='profile'] .profile-box .box-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    height: fit-content;
    padding: 0 15px;
    flex: 1 1 300px;
    word-break: break-word; }
  [data-component='profile'] .profile-box .box-bottom {
    display: flex;
    flex: 1 1 150px;
    padding: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    [data-component='profile'] .profile-box .box-bottom p {
      width: 100%; }
