[data-page="article"] .article .article-hero {
  position: relative;
  height: 600px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 0px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  max-height: calc(100vh - 150px); }
  @media (max-width: 575px) {
    [data-page="article"] .article .article-hero {
      height: 350px; } }
  [data-page="article"] .article .article-hero .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    z-index: 3; }
  [data-page="article"] .article .article-hero .hero-overlay {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 20px 40px;
    margin-left: -20px;
    margin-bottom: 20px;
    z-index: 5;
    max-width: calc(100%); }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-hero .hero-overlay {
        padding: 10px 10px;
        margin-left: -10px;
        margin-bottom: 10px; } }
  [data-page="article"] .article .article-hero .hero-author {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    margin-bottom: 10px;
    z-index: 5;
    max-width: calc(100%);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none;
    color: inherit;
    text-decoration: none;
    font-weight: 600; }
    [data-page="article"] .article .article-hero .hero-author:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-hero .hero-author {
        padding: 5px 10px;
        margin-left: -10px;
        font-size: 12px; } }
  [data-page="article"] .article .article-hero .hero-title {
    font-size: 32px;
    font-weight: 100;
    line-height: 1.3; }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-hero .hero-title {
        font-size: 20px; } }

[data-page="article"] .article .article-cats, [data-page="article"] .article .article-tags {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  margin-top: 30px;
  flex-wrap: wrap; }
  @media (max-width: 575px) {
    [data-page="article"] .article .article-cats, [data-page="article"] .article .article-tags {
      margin-top: 20px; } }
  [data-page="article"] .article .article-cats .article-cat, [data-page="article"] .article .article-tags .article-cat {
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
    [data-page="article"] .article .article-cats .article-cat:hover, [data-page="article"] .article .article-tags .article-cat:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-cats .article-cat, [data-page="article"] .article .article-tags .article-cat {
        margin-top: 5px;
        margin-left: 5px;
        padding: 5px 10px; } }
    [data-page="article"] .article .article-cats .article-cat:hover, [data-page="article"] .article .article-tags .article-cat:hover {
      background: #f2ae0d;
      color: white; }
  [data-page="article"] .article .article-cats .article-tag, [data-page="article"] .article .article-tags .article-tag {
    margin-top: 5px;
    margin-left: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
    [data-page="article"] .article .article-cats .article-tag:hover, [data-page="article"] .article .article-tags .article-tag:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    [data-page="article"] .article .article-cats .article-tag:hover, [data-page="article"] .article .article-tags .article-tag:hover {
      background: #f2ae0d;
      color: white; }

[data-page="article"] .article .article-tags {
  margin-left: -5px;
  margin-top: -5px; }

[data-page="article"] .article .article-summary {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  background: white;
  z-index: 5;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400; }
  @media (max-width: 575px) {
    [data-page="article"] .article .article-summary {
      font-size: 18px;
      margin-bottom: 20px; } }
  [data-page="article"] .article .article-summary a {
    color: inherit; }
  [data-page="article"] .article .article-summary p {
    font-size: inherit;
    font-weight: inherit; }
    [data-page="article"] .article .article-summary p:first-child {
      margin-top: 0;
      padding-top: 0; }

[data-page="article"] .article .article-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%; }

[data-page="article"] .article .article-date {
  margin-left: -60px;
  margin-bottom: 40px;
  padding: 5px 0px;
  padding-left: 40px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: fit-content; }
  [data-page="article"] .article .article-date .date-item {
    padding: 2px 20px;
    border-right: 1px solid #ddd;
    font-weight: 600;
    text-decoration: none;
    color: black; }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-date .date-item {
        padding: 2px 10px;
        font-size: 12px; } }
    [data-page="article"] .article .article-date .date-item:last-child {
      border: none; }
  [data-page="article"] .article .article-date a {
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none; }
    [data-page="article"] .article .article-date a:hover {
      color: #f3b520;
      transform: translateY(-2px); }

[data-page="article"] .article .article-share {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-left: -50px;
  margin-top: -90px;
  margin-bottom: 40px;
  flex-direction: column;
  margin-left: -90px;
  margin-top: -20px; }
  [data-page="article"] .article .article-share .share-item {
    height: 50px;
    width: 50px;
    background: #f2ae0d;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-left: 10px;
    margin-top: 10px;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: .15s;
    user-select: none; }
    [data-page="article"] .article .article-share .share-item:hover {
      transform: scale(1.05); }
    [data-page="article"] .article .article-share .share-item:active {
      transform: scale(0.95); }
    @media (max-width: 575px) {
      [data-page="article"] .article .article-share .share-item {
        display: none; } }

[data-page="article"] .article .article-share-bottom {
  color: white;
  padding: 30px 0;
  text-align: center;
  margin-bottom: 30px; }
  @media (max-width: 575px) {
    [data-page="article"] .article .article-share-bottom {
      padding: 20px 0;
      margin-bottom: 10px; } }
  [data-page="article"] .article .article-share-bottom .share-title {
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 33px;
    font-weight: 100;
    margin-bottom: 40px;
    text-transform: uppercase; }
    [data-page="article"] .article .article-share-bottom .share-title .fa-heart {
      animation: pulse 1s infinite; }
  [data-page="article"] .article .article-share-bottom .share-text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 2;
    font-weight: 300; }
  [data-page="article"] .article .article-share-bottom .share-buttons {
    display: flex;
    flex-direction: row;
    margin: -10px;
    flex-wrap: wrap; }
    [data-page="article"] .article .article-share-bottom .share-buttons .share-item {
      text-decoration: none;
      background: #444;
      color: white;
      flex: 1 1 50px;
      margin: 10px;
      min-width: 50px;
      line-height: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      position: relative;
      text-align: center;
      cursor: pointer;
      transition: .1s;
      cursor: pointer;
      transition: .15s;
      user-select: none; }
      [data-page="article"] .article .article-share-bottom .share-buttons .share-item:hover {
        transform: scale(1.05); }
      [data-page="article"] .article .article-share-bottom .share-buttons .share-item:active {
        transform: scale(0.95); }

[data-page="article"] .article .article-comments {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  background: white;
  padding: 60px;
  margin-top: 40px; }

[data-page="article"] .article .article-content {
  font-size: 17px;
  line-height: 2;
  font-weight: 300;
  margin-top: 40px;
  position: relative;
  padding: 60px;
  background: white; }
  @media (max-width: 575px) {
    [data-page="article"] .article .article-content {
      padding: 15px;
      margin-top: 20px;
      font-size: 14px; } }
  [data-page="article"] .article .article-content p {
    margin-bottom: 20px; }
  [data-page="article"] .article .article-content a {
    color: #f3b520;
    color: #d5990b;
    font-weight: 700; }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }
