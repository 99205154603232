[data-component="latest"] {
  position: relative; }
  @media (max-width: 991px) {
    [data-component="latest"] {
      display: none; } }
  [data-component="latest"] .latest-other {
    position: absolute;
    left: 520px;
    top: 0px;
    display: flex;
    flex-direction: row; }
  [data-component="latest"] .latest-title {
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: normal;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    background: #f3b520;
    color: white;
    flex: 1 0 auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-direction: row;
    height: 60px; }
    [data-component="latest"] .latest-title i {
      font-size: 45px;
      margin-right: 5px; }
  [data-component="latest"] .latest-left, [data-component="latest"] .latest-right {
    background: white;
    color: #f3b520;
    margin: 5px 0;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 10;
    border: 1px solid #eee;
    cursor: pointer;
    transition: .15s;
    user-select: none; }
    [data-component="latest"] .latest-left:hover, [data-component="latest"] .latest-right:hover {
      transform: scale(1.05); }
    [data-component="latest"] .latest-left:active, [data-component="latest"] .latest-right:active {
      transform: scale(0.95); }
    [data-component="latest"] .latest-left[data-inactive="true"], [data-component="latest"] .latest-right[data-inactive="true"] {
      color: #eee; }
      [data-component="latest"] .latest-left[data-inactive="true"]:hover, [data-component="latest"] .latest-right[data-inactive="true"]:hover {
        transform: none;
        cursor: default; }
  [data-component="latest"] .latest {
    display: flex;
    flex-direction: row;
    min-height: 450px;
    flex: 1 1 auto;
    position: relative;
    margin-left: -20px;
    align-items: flex-end;
    justify-content: flex-start;
    transition: .3s;
    will-change: left; }
    [data-component="latest"] .latest .latest-item {
      width: 300px;
      flex: 0 0 300px;
      border: 1px solid #eee;
      background: white;
      height: 370px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      transition: .3s;
      text-decoration: none;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
      transition: .2s;
      user-select: none;
      text-decoration: none; }
      [data-component="latest"] .latest .latest-item:hover {
        color: #f3b520;
        transform: translateY(-2px); }
      [data-component="latest"] .latest .latest-item:hover .item-image {
        filter: brightness(150%); }
      [data-component="latest"] .latest .latest-item[data-active="true"] {
        flex: 0 0 500px;
        width: 500px;
        height: 450px;
        margin-left: 20px; }
        [data-component="latest"] .latest .latest-item[data-active="true"] .item-image {
          height: 400px; }
        [data-component="latest"] .latest .latest-item[data-active="true"] .item-overlay {
          min-height: 130px; }
      [data-component="latest"] .latest .latest-item .item-image {
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: 1s;
        object-fit: cover;
        object-position: center;
        width: 100%; }
      [data-component="latest"] .latest .latest-item .item-overlay {
        padding: 20px;
        flex: 1 0 auto;
        min-height: 170px;
        background: white;
        color: black;
        display: flex;
        flex-direction: column;
        transition: .3s; }
        [data-component="latest"] .latest .latest-item .item-overlay .overlay-title {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          margin-top: 10px; }
        [data-component="latest"] .latest .latest-item .item-overlay .overlay-author {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          text-transform: uppercase;
          line-height: 1.2;
          font-family: 'Hind Vadodara', sans-serif;
          color: #f3b520; }
      [data-component="latest"] .latest .latest-item .overlay-stats {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        padding-top: 20px;
        margin-top: auto;
        display: flex;
        flex-direction: row;
        align-items: center; }
        [data-component="latest"] .latest .latest-item .overlay-stats .overlay-stat {
          padding: 0 10px;
          vertical-align: middle;
          display: flex;
          flex-direction: row;
          align-items: center; }
          [data-component="latest"] .latest .latest-item .overlay-stats .overlay-stat i {
            font-size: 16px;
            margin-right: 5px; }
          [data-component="latest"] .latest .latest-item .overlay-stats .overlay-stat:first-child {
            padding-left: 0;
            border-left: 0; }
