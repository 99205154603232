[data-component="feature"] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  [data-component="feature"] .feature {
    display: flex;
    flex-direction: row;
    min-height: 400px;
    flex: 1 1 auto;
    position: relative;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none; }
    [data-component="feature"] .feature .overlay-stats {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: uppercase;
      line-height: 1.2;
      font-family: 'Hind Vadodara', sans-serif;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      [data-component="feature"] .feature .overlay-stats .overlay-stat {
        padding: 0 10px;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        align-items: center; }
        [data-component="feature"] .feature .overlay-stats .overlay-stat i {
          font-size: 16px;
          margin-right: 5px; }
        [data-component="feature"] .feature .overlay-stats .overlay-stat:first-child {
          padding-left: 0;
          border-left: 0; }
    [data-component="feature"] .feature:hover {
      color: #f3b520;
      transform: translateY(-2px); }
    [data-component="feature"] .feature:hover .item-image {
      filter: brightness(150%); }
    [data-component="feature"] .feature:hover .item-overlay {
      transform: translateY(-2px); }
    [data-component="feature"] .feature .item-image {
      flex: 1 0 70%;
      object-fit: cover;
      object-position: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
      transition: 1s; }
    [data-component="feature"] .feature .item-overlay {
      height: auto;
      align-self: flex-start;
      padding: 20px;
      position: relative;
      z-index: 2;
      min-height: 200px;
      background: white;
      color: black;
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
      margin-right: -100px;
      margin-top: 40px;
      transition: .2s; }
      [data-component="feature"] .feature .item-overlay .overlay-title {
        font-size: 32px;
        font-weight: 100;
        line-height: 1.3;
        margin-top: 10px;
        background: white; }
        @media (max-width: 575px) {
          [data-component="feature"] .feature .item-overlay .overlay-title {
            font-size: 20px; } }
      [data-component="feature"] .feature .item-overlay .overlay-author {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: 'Hind Vadodara', sans-serif;
        color: #f3b520; }
