[data-component="quote"] .quote {
  font-size: 32px;
  font-weight: 100;
  line-height: 1.3;
  font-size: 30px;
  padding: 20px 70px;
  max-width: 650px;
  position: relative;
  margin: 0 auto 60px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
  text-align: left; }
  @media (max-width: 575px) {
    [data-component="quote"] .quote {
      font-size: 20px; } }
  [data-component="quote"] .quote i {
    position: absolute;
    color: #f3b520; }
  [data-component="quote"] .quote .fa-quote-left {
    top: 20px;
    left: 20px; }
  [data-component="quote"] .quote .fa-quote-right {
    bottom: 20px;
    right: 20px; }

[data-component="quote"] .quote-author {
  padding: 10px 20px;
  background: #f2ae0d;
  color: white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'Hind Vadodara', sans-serif;
  margin: 0 auto;
  position: absolute;
  right: 70px;
  bottom: -30px; }
