[data-page="search"] {
  display: flex;
  flex-direction: column; }
  [data-page="search"] .search-results {
    flex: 1; }
  [data-page="search"] .search-input {
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    margin: 0 auto;
    margin-top: 20px;
    height: 50px;
    background: white;
    border: none;
    width: 400px;
    border: 1px solid #ddd;
    padding: 0 10px;
    outline: none; }
