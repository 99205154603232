[data-component="loader"] {
  min-height: 500px;
  z-index: 10000;
  user-select: none;
  pointer-events: none;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .6s; }
  [data-component="loader"][data-visible="true"] {
    opacity: 1; }
