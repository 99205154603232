html body {
  min-width: 320px;
  background: #f9f9f9f9;
  font-family: 'Hind Vadodara', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  overflow-x: hidden; }
  html body img {
    object-fit: cover;
    object-position: center; }
  @media (max-width: 575px) {
    html body {
      font-size: 12px; } }
  html body .gap {
    height: 40px; }
  html body b, html body strong {
    font-weight: 700; }
  html body h1, html body h2 {
    margin-bottom: 40px;
    margin-top: 40px; }
    html body h1:first-child, html body h2:first-child {
      margin-top: 0; }
  html body h1 {
    font-size: 32px;
    font-weight: 100;
    line-height: 1.3;
    font-size: 32px; }
    @media (max-width: 575px) {
      html body h1 {
        font-size: 20px; } }
  html body h2 {
    font-size: 32px;
    font-weight: 100;
    line-height: 1.3;
    font-size: 30px; }
    @media (max-width: 575px) {
      html body h2 {
        font-size: 20px; } }
  html body p a {
    color: #d5990b;
    font-weight: 500; }
  html body .section-label, html body .small-label {
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: normal;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    width: auto;
    background: #f3b520;
    color: white;
    flex: 1 0 auto;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-direction: row;
    height: 60px;
    margin-bottom: 20px; }
    @media (max-width: 575px) {
      html body .section-label, html body .small-label {
        font-size: 20px;
        padding: 5px 20px; } }
    html body .section-label i, html body .small-label i {
      font-size: 45px;
      margin-right: 5px; }
  html body .small-label {
    font-size: 20px;
    height: 40px;
    margin-bottom: 20px; }
  html body .section {
    padding: 50px 0;
    width: 100%; }
    @media (max-width: 575px) {
      html body .section {
        padding: 10px 0; } }
    html body .section.section-alt {
      background: #fff; }
    html body .section.section-dark {
      background: #111;
      color: white; }
    html body .section[data-top-padding="false"] {
      padding-top: 0; }
    html body .section[data-bottom-padding="false"] {
      padding-bottom: 0; }
  @media (min-width: 768px) {
    html body .container {
      max-width: 750px; } }
  @media (min-width: 992px) {
    html body .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    html body .container {
      max-width: 1070px; } }
  @media (min-width: 1300px) {
    html body .container {
      max-width: 1170px; } }
  @media (min-width: 1400px) {
    html body .container {
      max-width: 1270px; } }
  @media (min-width: 1500px) {
    html body .container {
      max-width: 1370px; } }
  html body [data-page] {
    min-height: 100vh;
    display: flex;
    flex-direction: column; }
  html body [data-screen] {
    min-height: 100vh; }
    html body [data-screen][data-visible="true"] {
      opacity: 1; }
  html body [data-component][data-visible] {
    opacity: 0;
    transition: .6s;
    transition-delay: .3s; }
  html body [data-component][data-visible="true"] {
    opacity: 1; }
  html body .row {
    margin-left: -20px;
    margin-right: -20px; }
  html body [class^="col"] {
    position: relative;
    padding-right: 20px;
    padding-left: 20px; }
  @media (min-width: 576px) {
    html body .visible-mobile {
      display: none !important; } }
  html body .btn {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Hind Vadodara', sans-serif;
    height: 50px;
    background: #f3b520;
    color: white;
    padding: 0 20px;
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .15s;
    user-select: none;
    text-decoration: none;
    border-radius: 0;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: .15s;
    user-select: none; }
    html body .btn:hover {
      transform: scale(1.05); }
    html body .btn:active {
      transform: scale(0.95); }

.flex {
  display: flex; }
  .flex.flex-row {
    flex-direction: row; }
  .flex.flex-wrap {
    flex-wrap: wrap; }
  .flex.flex-align-baseline {
    align-items: baseline; }
