[data-component="sidebar"] {
  position: fixed;
  z-index: 2000; }
  [data-component="sidebar"][data-active="true"] .sidebar-background {
    visibility: visible;
    opacity: 1;
    z-index: 2; }
  [data-component="sidebar"][data-active="true"] .sidebar {
    visibility: visible;
    opacity: 1;
    z-index: 3;
    transform: translateX(0); }
  [data-component="sidebar"] .sidebar-background {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer; }
  [data-component="sidebar"] .sidebar {
    transform: translateX(-30px);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 400px;
    background: white;
    background: #f3b520;
    color: black;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding-top: 50px; }
    [data-component="sidebar"] .sidebar .sidebar-x {
      position: fixed;
      top: 3px;
      left: 0;
      width: 50px;
      height: 50px;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      cursor: pointer;
      transition: .15s;
      user-select: none; }
      [data-component="sidebar"] .sidebar .sidebar-x:hover {
        transform: scale(1.05); }
      [data-component="sidebar"] .sidebar .sidebar-x:active {
        transform: scale(0.95); }
    [data-component="sidebar"] .sidebar .menu-group {
      margin: 0 30px;
      margin-top: 30px; }
      [data-component="sidebar"] .sidebar .menu-group .group-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        margin-left: -30px;
        width: fit-content;
        background: black;
        color: white;
        padding: 15px 30px;
        padding-left: 30px; }
      [data-component="sidebar"] .sidebar .menu-group .group-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -30px;
        margin-top: 15px; }
        [data-component="sidebar"] .sidebar .menu-group .group-links .group-link {
          flex: 1 1 auto;
          min-width: 150px;
          padding: 6px 0;
          padding-right: 30px;
          color: black;
          cursor: pointer;
          transition: .2s;
          user-select: none;
          text-decoration: none;
          font-size: 15px;
          font-weight: 300; }
          [data-component="sidebar"] .sidebar .menu-group .group-links .group-link:hover {
            color: #f3b520;
            transform: translateY(-2px); }
          [data-component="sidebar"] .sidebar .menu-group .group-links .group-link:hover {
            color: inherit; }
